import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper/modules";
import DestinationContent from "./DestinationContent";
import DestinationTourList from "./DestinationTourList";
import Header from "../../../Components/Header/Header";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Footer from "../../../Components/Footer/Footer";
import { useParams } from "react-router-dom";
import { useApi } from "../../../Context/ApiProvider";
import { Image_URL } from "../../../Common/BaseURL";

const DestinationDetail = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { destinationId } = useParams();
  const [destination, setDestination] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchDestinationDetail = async () => {
      try {
        const response = await api.get(
          `Destination/DestinationcDetail?name=${destinationId}`
        ); // Update API URL

        setDestination(response.body.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching destination details:", error);
      }
    };

    const handleFetchData = () => {
      fetchDestinationDetail();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api, destinationId]); // include api if it is subject to change

  if (loading) {
    return (
      <div className="loading">
        <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Destination Detail" link="/DestinationDetail" />

      <section className="destination-details-section section-padding2">
        <div className="container">
          <div className="row g-4">
            <div className="col-xl-8 col-lg-7">
              <div className="destination-details-banner o-hidden radius-12">
                <div className="swiper destinationSwiper-active">
                  <Swiper
                    slidesPerView={1}
                    speed={1000}
                    loop={true}
                    autoplay={{
                      delay: 2000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    modules={[Autoplay]}
                  >
                    <SwiperSlide>
                      <img
                        src={`${Image_URL}/city/${destination.fileName}`}
                        alt="Routes and Tours"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <DestinationContent
                content={destination}
              />
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="row g-4 position-static top-0">
                {/* <div className="col-lg-12">
                  <DestinationOffer />
                </div> */}
                <div className="col-lg-12">
                  <DestinationTourList
                    content={destination}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default DestinationDetail;
