import { Routes, Route, useLocation } from "react-router-dom";
import Index from "./Components/Home/Index";
import { useCallback, useEffect, useState } from "react";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import ForgotPassword from "./Auth/ForgotPassword/ForgotPassword";
import Verification from "./Auth/Verification/Verification";
import NewPassword from "./Auth/NewPassword/NewPassword";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import FAQ from "./Pages/FAQ/FAQ";
import Privacy from "./Pages/Privacy/Privacy";
import TermsCondition from "./Pages/TermsCondition/TermsCondition";
import Payment from "./Pages/Payment/Payment";
import TourList from "./Pages/Tour/TourList/TourList";
import DestinationList from "./Pages/Destination/DestinationList/Destination";
import DestinationDetail from "./Pages/Destination/DestinationDetail/Destination";
import TourDetail from "./Pages/Tour/TourDetail/TourDetail";
import ApiProvider from "./Context/ApiProvider";
import Dashboard from "./UserDashboard/Dashboard";

function App() {
  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "dark"
  );
  const location = useLocation();

  const updateLogoOnTheme = useCallback(() => {
    const changeLogos = document.querySelectorAll(".changeLogo");
    const whiteLogoPath = document.head
      .querySelector("meta[white-logo]")
      .getAttribute("white-logo");
    const lightLogoPath = document.head
      .querySelector("meta[logo]")
      .getAttribute("logo");

    const imagePath = theme === "dark" ? whiteLogoPath : lightLogoPath;

    changeLogos.forEach((changeLogo) => {
      changeLogo.src = imagePath;
    });
  }, [theme]);

  const themeModeAction = useCallback(() => {
    const themeToggleButton = document.querySelector(".change-theme-mode i");
    if (themeToggleButton) {
      themeToggleButton.className =
        theme === "dark" ? "ri-moon-line" : "ri-sun-line";
    }
  }, [theme]);

  useEffect(() => {
    // Update the logo based on the current theme
    updateLogoOnTheme();

    // Update the theme toggle button icon
    themeModeAction();
  }, [theme, updateLogoOnTheme, themeModeAction, location]); // Ensure this effect runs whenever 'theme' changes

  const toggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    // Apply the current theme to the document body or root element
    document.documentElement.dataset.theme = theme;
  }, [theme]);

  return (
    <ApiProvider>
      <Routes>
        <Route path="/" element={<Index toggleTheme={toggleTheme} />} />
        <Route path="/Login" element={<Login toggleTheme={toggleTheme} />} />
        <Route
          path="/Register"
          element={<Register toggleTheme={toggleTheme} />}
        />
        <Route
          path="/ForgotPassword"
          element={<ForgotPassword toggleTheme={toggleTheme} />}
        />
        <Route
          path="/Verification"
          element={<Verification toggleTheme={toggleTheme} />}
        />
        <Route
          path="/newPassword"
          element={<NewPassword toggleTheme={toggleTheme} />}
        />
        <Route
          path="/TourList"
          element={<TourList toggleTheme={toggleTheme} />}
        />
        <Route
          path="/TourDetail/:packageId"
          element={<TourDetail toggleTheme={toggleTheme} />}
        />
        <Route
          path="/DestinationList"
          element={<DestinationList toggleTheme={toggleTheme} />}
        />
        <Route
          path="/DestinationDetail/:destinationId"
          element={<DestinationDetail toggleTheme={toggleTheme} />}
        />
        <Route path="/About" element={<About toggleTheme={toggleTheme} />} />
        <Route
          path="/Contact"
          element={<Contact toggleTheme={toggleTheme} />}
        />
        <Route path="/faq" element={<FAQ toggleTheme={toggleTheme} />} />
        <Route
          path="/Privacy"
          element={<Privacy toggleTheme={toggleTheme} />}
        />
        <Route
          path="/TermsCondition"
          element={<TermsCondition toggleTheme={toggleTheme} />}
        />
        <Route
          path="/Payment"
          element={<Payment toggleTheme={toggleTheme} />}
        />

        {/* User dashboard after login */}
        <Route
          path="/Dashboard"
          element={<Dashboard toggleTheme={toggleTheme} />}
        />
      </Routes>
    </ApiProvider>
  );
}

export default App;
