import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../../Context/ApiProvider";

const TopTour = () => {
  const [topTour, setTopTour] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchTopTourData = async () => {
      try {
        const response = await api.get(`Package/PackageDestinationList`); // Update API URL
        setTopTour(response.body.data);
      } catch (error) {
        console.error("Error fetching about:", error);
      }
    };

    const handleFetchData = () => {
      fetchTopTourData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]);

  return (
    <section className="special-area">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title mx-430 mx-auto text-center">
              <span className="highlights fancy-font font-400">Enjoy Trip</span>
              <h4 className="title">Top Tour</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-domestic"
                role="tabpanel"
                aria-labelledby="pills-domestic-tab"
              >
                <div className="row g-4">
                  {topTour &&
                    topTour.slice(0, 6).map((tour, index) => (
                      <div className="col-xl-4 col-md-6" key={index}>
                        <Link
                          to={`/TourDetail/${tour.packageName}`}
                          className="trip-card"
                        >
                          <div className="from-flex">
                            <h4 className="from-title">{tour.cityFromName}</h4>
                            <p className="from-pera line-clamp-1">
                              {tour.stateFromName}
                            </p>
                          </div>
                          <div className="trip-icon-flex">
                            <div className="trip-icon">
                              <i className="ri-flight-takeoff-fill"></i>
                            </div>
                          </div>
                          <div className="from-flex">
                            <h4 className="from-title">{tour.cityToName}</h4>
                            <p className="from-pera line-clamp-1">
                              {tour.stateToName}
                            </p>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopTour;
