import React from "react";
import { Link } from "react-router-dom";
import { Image_URL } from "../../../Common/BaseURL";

const DestinationTourList = ({ content }) => {
  return (
    <div className="tour-list-card">
      <h4 className="title">Tour List</h4>
      <ul className="tour-listing">
        {content?.packages?.slice(0, 5).map((pack, index) => (
          <li key={index} className="list">
            <div className="package-img imgEffect4">
              <Link to={`/TourDetail/${pack.packageName}`}>
                <img src={`${Image_URL}/Package/${pack.imagename}`} alt="Routes and Tours" />
              </Link>
            </div>
            <div className="package-content">
              <h4 className="area-name">
                <Link to={`/TourDetail/${pack?.packageName}`}>{pack?.packageName}</Link>
              </h4>
              <div className="location">
                <i className="ri-map-pin-line"></i>
                <div className="name">{pack?.stateName}</div>
              </div>
              <div className="price-review">
                <div className="d-flex gap-10">
                  <p className="light-pera">From</p>
                  <p className="pera">₹{pack?.priceForDouble}</p>
                </div>
                <div className="rating">
                  <p className="pera">Per Person on twin sharing</p>
                </div>
                {/* <div className="rating">
                  <i className="ri-star-s-fill"></i>
                  <p className="pera">4.7 (20 Reviews)</p>
                </div> */}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="text-center mt-10">
        <Link to="/TourList" className="see-more-text" aria-label="see">
          See More
        </Link>
      </div>
    </div>
  );
};

export default DestinationTourList;
