import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header/Header";
import Breadcrumbs from "../../../Common/Breadcrumbs";
import Footer from "../../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import { useApi } from "../../../Context/ApiProvider";
import { Image_URL } from "../../../Common/BaseURL";

const DestinationList = ({ toggleTheme }) => {
  const [destination, setDestination] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchDestinationData = async () => {
      try {
        const response = await api.get(`Destination/Destinationc`); // Update API URL
        setDestination(response.body.data);
      } catch (error) {
        console.error("Error fetching feature:", error);
      } finally {
        setLoading(false);
      }
    }

    const handleFetchData = () => {
      fetchDestinationData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <div className="loading">
        <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    );
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <Breadcrumbs heading="Destination" link="/DestinationList" />

      <section className="destination-section bottom-padding1">
        <div className="destination-area">
          <div className="container">
            <div className="row g-4">
              {destination.map((destination, index) => (
                <div key={index} className={`col-lg-${destination.colSize || 4} col-sm-6`}>
                  <Link
                    to={`/DestinationDetail/${destination.name}`}
                    className="destination-banner h-calc"
                  >
                    <img
                      src={
                        destination.fileName
                          ? `${Image_URL}/city/${destination.fileName}`
                          : "assets/images/package/package-4.png"
                      }
                      alt={destination.name || "destination"}
                    />
                    <div className="destination-content">
                      {/* <div className="ratting-badge">
                        <i className="ri-star-s-fill"></i>
                        <span>{destination.rating || "4.5"}</span>
                      </div> */}
                      <div className="destination-info">
                        <div className="destination-name">
                          <p className="pera">{destination.name}</p>
                        </div>
                        {/* <div className="destination-name">
                          <p className="pera">{destination.country || "Unknown Country"}</p>
                          <div className="location">
                            <i className="ri-map-pin-line"></i>
                            <p className="name">{destination.location || "Unknown Location"}</p>
                          </div>
                        </div> */}
                        <div className="button-section">
                          <div className="arrow-btn">
                            <i className="ri-arrow-right-line"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
              {/* Optionally add a special component like DestinationOffer */}
              {/* <div className="col-lg-4 col-sm-6">
                <DestinationOffer />
              </div> */}
              {/* <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-8 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest1}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">New Zealand</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Auckland View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6">
                <DestinationOffer />
              </div> */}
              {/* <div className="col-lg-5 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest3}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">New Zealand</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Auckland View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-7 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest2}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Spain</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Malaga View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Switzerland</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Zürich View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Australia</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Melbourne View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="col-lg-4 col-sm-6">
                <Link
                  to="/DestinationDetail"
                  className="destination-banner h-calc"
                >
                  <img
                    src={dest}
                    alt="Routes and Tours"
                  />
                  <div className="destination-content">
                    <div className="ratting-badge">
                      <i className="ri-star-s-fill"></i>
                      <span>4.5</span>
                    </div>
                    <div className="destination-info">
                      <div className="destination-name">
                        <p className="pera">Canada</p>
                        <div className="location">
                          <i className="ri-map-pin-line"></i>
                          <p className="name">Toronto View</p>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="arrow-btn">
                          <i className="ri-arrow-right-line"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default DestinationList;
