import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import shape from "../../Assets/images/icon/shape.png"
import { Image_URL } from "../../Common/BaseURL";
import { useApi } from "../../Context/ApiProvider";

const Destination = () => {
  const [destination, setDestination] = useState([]);
  const api = useApi();

  useEffect(() => {
    const fetchDestinationData = async () => {
      try {
        const response = await api.get(`Destination/Destinationc`); // Update API URL
        setDestination(response.body.data);
      } catch (error) {
        console.error("Error fetching feature:", error);
      }
    }

    const handleFetchData = () => {
      fetchDestinationData();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api])

  // Split the destinations into two parts
  const mainDestinations = destination.slice(0, 3); // First 3 destinations for the main section
  const galleryDestinations = destination.slice(3, 7); // Next 4 destinations for the gallery section

  return (
    <section className="destination-area destination-bg-before">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-7">
            <div className="section-title text-center mx-430 mx-auto position-relative">
              <span className="highlights">Destination List</span>
              <h4 className="title">Explore The Beautiful Places Around World</h4>
            </div>
          </div>
        </div>
        <div className="row g-4">
          {mainDestinations.map((destination, index) => (
            <div className="col-xl-4 col-lg-6 col-md-6" key={index}>
              <Link
                to={`/DestinationDetail/${destination.name}`}
                className="destination-banner"
              >
                <img
                  className="destination-image"
                  src={`${Image_URL}/city/${destination.fileName}`} // Assuming the image URL is stored in the destination object
                  alt={destination.name} // Assuming the name is stored in the destination object
                />
                <div className="destination-content">
                  <div className="destination-info">
                    <div className="destination-name">
                      <p className="pera">{destination.name}</p> {/* Assuming the name is stored in the destination object */}
                      {/* <div className="location">
                        <i className="ri-map-pin-line"></i>
                        <p className="name">{destination.location}</p> 
                      </div> */}
                    </div>
                    <div className="button-section">
                      <div className="arrow-btn">
                        <i className="ri-arrow-right-line"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
          <div className="destination-gallery">
            <div className="row g-4">
              {galleryDestinations.map((destination, index) => (
                <div className="col-lg-3 col-md-6 col-sm-6" key={index}>
                  <Link
                    to={`/DestinationDetail/${destination.name}`}
                    className="destination-banner"
                  >
                    <img
                      className="destination-gallery-image"
                      src={`${Image_URL}/city/${destination.fileName}`} // Assuming the image URL is stored in the destination object
                      alt={destination.name} // Assuming the name is stored in the destination object
                    />
                    <div className="destination-content">
                      <div className="destination-info">
                        <div className="destination-name">
                          <p className="pera">{destination.name}</p>
                        </div>
                        <div className="button-section">
                          <div className="arrow-btn">
                            <i className="ri-arrow-right-line"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="shape">
        <img src={shape} alt="Routes and Tours" />
      </div>
    </section>
  );
};

export default Destination;
