import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SpecialOffer from "./SpecialOffer";
import AboutUs from "./AboutUs";
import Hero from "./Hero";
import PlanArea from "./PlanArea";
import FeatureTour from "./FeatureTour";
import Destination from "./Destination";
import Package from "./Package";
import Testimonial from "./Testimonial";
import Brand from "./Brand";
import { useApi } from "../../Context/ApiProvider";
import TopTour from "./TopTour";

const Index = ({ toggleTheme }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [about, setAbout] = useState(null);
  const [tours, setTours] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [loading, setLoading] = useState(true);
  const api = useApi();

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const response = await api.get(`CMS/ByCMSName?title=about`); // Update API URL

        setAbout(response.body.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching about:", error);
      }
    };

    const fetchTours = async () => {
      try {
        const response = await api.get("TourType/TourTypeList");
        setTours(response.body.data || []);
        setLoading(false);
        if (response.body.data.length > 0) {
          setActiveTab(response.body.data[0].tourTypeId); // Set default active tab
        }
      } catch (err) {
        console.error("Error fetching destinations", err);
      }
    };

    const handleFetchData = () => {
      fetchAbout();
      fetchTours();
    };

    const timeoutId = setTimeout(handleFetchData, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [api]); // include api if it is subject to change

  const handleTabClick = (tourTypeId) => {
    setActiveTab(tourTypeId);
  };

  if (loading) {
    return <div className="loading">
      <h2 className="highlights fancy-font font-400">Routes and Tours</h2>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  }

  return (
    <div>
      <Header toggleTheme={toggleTheme} />
      <main>
        <Hero />
        <PlanArea tours={tours} />
        <SpecialOffer />
        <TopTour />
        <AboutUs content={about} />
        <FeatureTour />
        <Destination />
        <Package
          tours={tours}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
        />
        <Testimonial />
        {/* <Brand /> */}
      </main>
      <Footer />
    </div>
  );
};

export default Index;
